import {
  ADVANCE_CROWN_ICON,
  PRO_CROWN_ICON,
  STARTED_CROWN_ICON,
} from "Assets/icons";

const LISTING_SORTERS = [
  { label: "Liên quan nhất", value: "relatest" },
  { label: "Theo giá tiền (Từ cao đến thấp)", value: "price down" },
  { label: "Theo giá tiền (Từ thấp đến cao)", value: "price up" },
  { label: "Mới nhất", value: "newest" },
];

const COUNTRY_FLAG = (code: string) => `https://flagcdn.com/w20/${code}.png`;

export const COUNTRY_CODES = {
  en: "en",
  vi: "vi",
};

const COUNTRIES = [
  {
    value: COUNTRY_CODES.en,
    label: "English",
    matchCode: "gb",
    src: COUNTRY_FLAG("gb"),
  },
  {
    value: COUNTRY_CODES.vi,
    label: "Việt Nam",
    matchCode: "vn",
    src: COUNTRY_FLAG("vn"),
  },
];

const GENDERS = [
  { value: "male", label: "Common.male" },
  { value: "female", label: "Common.female" },
  { value: "other", label: "Common.other" },
];

export const TRADE_TERMS = [
  "FOB",
  "EXW",
  "FCA",
  "FAS",
  "CFR",
  "CIF",
  "CPT",
  "CIP",
  "DAT",
  "DDP",
  "DAP",
];

export const MAX_BUDGETS = [
  "<1000",
  "1000~5000",
  "5000~10000",
  "10000~50000",
  ">50000",
];

export const SHIPPING_METHODS = [
  "Sea freight",
  "Air freight",
  "Express",
  "Land freight",
];

export const PAYMENT_TERMS = [
  "T/T",
  "L/C",
  "D/P",
  "W/U",
  "Money Gram",
  "PayPal",
  "Other",
];

export const NUMBER_OF_EMPLOYEES = [
  "1-10",
  "11-50",
  "51-100",
  "101-500",
  "501-1000",
  ">1000",
];

export const FREE_SUPPLIER_PLAN = "Free";

export const PLAN_NAMES = {
  free: "Free",
  trial: "Trial",
  pro: "Pro",
  advance: "Advance",
  starter: "Starter",
};

export const SUPPLIER_PLANS = [
  { label: PLAN_NAMES.free, planTextColor: "#9C2A10" },
  { label: PLAN_NAMES.pro, icon: PRO_CROWN_ICON, planTextColor: "#FDB022" },
  {
    label: PLAN_NAMES.advance,
    icon: ADVANCE_CROWN_ICON,
    planTextColor: "#293056",
  },
  {
    label: PLAN_NAMES.starter,
    icon: STARTED_CROWN_ICON,
    planTextColor: "#9C2A10",
  },
  {
    label: PLAN_NAMES.trial,
    planTextColor: "#9C2A10",
    icon: STARTED_CROWN_ICON,
  },
];

export const VERIFICATION_LEVEL = {
  level1: "Level 1",
  level2: "Level 2",
  level3: "Level 3",
};

export const VERIFICATION_LEVELS = {
  level1: { title: "Level 1", daysToQuote: 14 },
  level2: { title: "Level 2", daysToQuote: 14 },
  level3: { title: "Level 3", daysToQuote: 7 },
};

export const REPORT_TYPE = {
  rfq: "RFQ",
  buyer: "Buyer",
  supplier: "Supplier",
  product: "Product",
};

export const ROLES = {
  buyer: "Buyer",
  supplier: "Seller",
  trader: "Both",
};

export const POSITIONS = [
  { label: "Founder/ Owner", value: "Founder/ Owner" },
  { label: "C-Level Manager", value: "C-Level Manager" },
  {
    label: "Sale/ Marketing/ Sourcing Manager",
    value: "Sale/ Marketing/ Sourcing Manager",
  },
  { label: "Staff", value: "Staff" },
  { label: "Freelancer/ Contractor", value: "Freelancer/ Contractor" },
  { label: "Other", value: "Other" },
];

export { LISTING_SORTERS, COUNTRIES, GENDERS };
